import React, { useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";

import BonusChip from "../../../../general/bonus-chip/BonusChip";
import CheckoffChip from "../../../../general/checkoff-chip/CheckoffChip";
import HomeworkChip from "../../../../general/homework-chip/HomeworkChip";

import CheckCircleButton from "../../../../general/check-circle-button/CheckCircleButton";

import firebase, { db, auth } from "../../../../../utils/firebase";
import AuthContext from "../../../../../store/auth-context";

import styles from "./ExerciseRow.module.css";

export default function ExerciseRow({
  id,
  progressDocId,
  name,
  project,
  description,
  section,
  chapter,
  isCheckoff,
  isHomework,
  isBonus,
  deadlineTimestamp,
  isCompleted,
  isLocked,
  homeworkCompletion,
}: any) {
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  // const [isCompletedState, setIsCompletedState] = React.useState(isCompleted);
  const userData: any = useContext(AuthContext);

  let formattedDeadlineDate;
  if (deadlineTimestamp) {
    formattedDeadlineDate =
      deadlineTimestamp.getMonth() +
      1 +
      "/" +
      deadlineTimestamp.getDate() +
      "/" +
      deadlineTimestamp.getFullYear();
  } else {
    formattedDeadlineDate = "N/A";
  }
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        // sx={{ borderBottom: "unset" }}
      >
        {/* <TableCell width={50}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        <TableCell align="left" component="th" scope="row">
          <Typography
            onClick={() =>
              window.open(
                "/tutorials/?project=" +
                  project.toLowerCase() +
                  "&section=" +
                  section +
                  "&chapter=" +
                  chapter +
                  (isBonus ? "&pageType=exercises" : ""),
                "_blank"
              )
            }
            variant="subtitle1"
            className={styles.exerciseLink}
          >
            {name}
          </Typography>
        </TableCell>
        {/* <TableCell align="right">{name}</TableCell> */}
        <TableCell align="right">
          {
            // isHomework ? (
            //   <HomeworkChip />
            // ) : isBonus ? (
            //   <BonusChip />
            // ) :
            isCheckoff ? <CheckoffChip /> : null
          }
        </TableCell>
        {/* <TableCell align="right">{formattedDeadlineDate}</TableCell> */}
        <TableCell align="right">
          <Box sx={{ m: 1, position: "relative" }}>
            <CheckCircleButton
              exerciseId={id}
              progressDocId={progressDocId}
              isCheckoff={isCheckoff}
              isCompleted={isCompleted}
              isLocked={isLocked}
              size="large"
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Box mb={2}>
                <Typography
                  display="inline"
                  variant="body1"
                  gutterBottom
                  component="div"
                >
                  {project + " kit"}
                </Typography>
                <Typography
                  display="inline"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  {" - Section " + section + " Chapter " + chapter}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">{description}</Typography>
              </Box>
              <Button
                // component={Link}
                // to={
                //   "/tutorials/?project=" +
                //   kit +
                //   "&section=" +
                //   section +
                //   "&chapter=" +
                //   chapter
                // }
                // href={
                //   "/tutorials/?project=" +
                //   project.toLowerCase() +
                //   "&section=" +
                //   section +
                //   "&chapter=" +
                //   chapter
                // }
                onClick={() =>
                  window.open(
                    "/tutorials/?project=" +
                      project.toLowerCase() +
                      "&section=" +
                      section +
                      "&chapter=" +
                      chapter,
                    "_blank"
                  )
                }
                variant="contained"
                color="info"
                size="small"
              >
                View chapter
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
