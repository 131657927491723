import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router";

import { auth, db } from "../../utils/firebase";

import { Link } from "react-router-dom";

import { Grid, Typography, Box, IconButton, Skeleton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChaptersTable from "../../components/student-dashboard-page/chapters-table/ChaptersTable";

import { Student, Exercise } from "../../types/general-types";
import { truncateSync } from "fs";

// const StyledLoginButton = styled(Button)({
//   float: "right",
// });

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function convertToProjectName(projectId: string) {
  const words = projectId.split("-");
  let projectName = "";
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    word = word[0].toUpperCase() + word.slice(1);
    projectName += word;
    if (i < words.length - 1) {
      projectName += " ";
    }
  }
  return projectName;
}

export default function StudentDashboardPage({
  userData,
  students,
  classroomProjectsData,
}: any) {
  const params: any = useParams();
  const [sectionExercises, setSectionExercises] = useState<Exercise[][] | null>(
    null
  );
  const [projects, setProjects] = useState<any | null>();
  const [chapterNames, setChapterNames] = useState<any | null>();
  const history = useHistory();

  let student: any;
  if (userData.isTeacher) {
    student = students.find((student: Student) => {
      return student.id === params.userId;
    });
    if (!student && students.length > 0) {
      history.push("/");
    }
  }
  // else if (userData.id == params.userId) {
  //   student = userData;
  // } else {
  //   history.push("/");
  // }
  else {
    student = userData;
  }

  useEffect(() => {
    // get the classroom content I SHOULD BE DYNAMICALLY GETTING THE CLASSROOM ID FROM THE CURRENT STUDENT'S SELECTED CLASSROOM
    if (!student) {
      return;
    }
    var classroomContentRef = db
      .collection("classroomContent")
      // .where("classroomIds", "array-contains", student.classroomId);
      // change back to the way above later
      .where("classroomIds", "array-contains", "derxNawjKfsyyx60ymu8");
    classroomContentRef.onSnapshot((querySnapshot) => {
      console.log("classroom content");
      querySnapshot.forEach((doc) => {
        const docExercises = doc.data().exercises;
        const docChapterDueDateData = doc.data().chapterDueDateData;

        // get chapterlockdata from chapterLockData collection
        db.collection("chapterLockData")
          .doc(student.classroomId)
          .onSnapshot((chapterLockDataDoc: any) => {
            // do something with the chapter lock data
            let chapterLockData =
              chapterLockDataDoc.data() && "data" in chapterLockDataDoc.data()
                ? chapterLockDataDoc.data().data
                : {};

            db.collection("progress")
              .where("userId", "==", student.id)
              // .where('projectId', '==', 'ping-pong-launcher')
              .onSnapshot((querySnapshot) => {
                let startDate = doc.data().startTimestamp.toDate();
                let chapterNames = doc.data().chapterNames;

                startDate = new Date();
                if (querySnapshot.size > 0) {
                  let projectsProgress: any = {};
                  querySnapshot.forEach((doc) => {
                    projectsProgress[doc.data().projectId] = {
                      progressDocId: doc.id,
                      projectId: doc.data().projectId,
                      progressDict: doc.data().data,
                    };
                  });

                  let projects: any = {};
                  let projectExercises: Exercise[][][] = [];
                  let sectionExercises: Exercise[][] = [];
                  let chapterExercises: Exercise[] = [];

                  for (const projectId in doc.data().sectionsChaptersCount) {
                    if (
                      userData.classroomProjects.length > 0 &&
                      !userData.classroomProjects.includes(projectId)
                    ) {
                      continue;
                    } else if (
                      userData.classroomProjects.length == 0 &&
                      projectId == "smart-doorbell"
                    ) {
                      continue;
                    }
                    projectExercises = Array(
                      doc.data().sectionsChaptersCount[projectId].length
                    );
                    let i = 0;
                    for (const section of doc.data().sectionsChaptersCount[
                      projectId
                    ]) {
                      sectionExercises = [...Array(section)].map((e) =>
                        Array(0)
                      );
                      projectExercises[i] = sectionExercises;
                      i += 1;
                    }

                    projects[projectId] = projectExercises;
                  }
                  // console.log(projects);

                  let lastProjectId =
                    userData.classroomProjects.length > 0
                      ? "smart-doorbell"
                      : "essentials";
                  let lastSection = 1;
                  let lastChapter = 1;
                  for (const docExercise of docExercises) {
                    if (!(docExercise.projectId in projectsProgress)) {
                      continue;
                    }
                    const progressDoc = projectsProgress[docExercise.projectId];
                    const progressDict = progressDoc.progressDict;

                    let projectId = docExercise.projectId;

                    if (
                      userData.classroomProjects.length > 0 &&
                      !userData.classroomProjects.includes(projectId)
                    ) {
                      continue;
                    } else if (
                      userData.classroomProjects.length == 0 &&
                      projectId == "smart-doorbell"
                    ) {
                      continue;
                    }

                    const exercise: Exercise = {
                      id: docExercise.id,
                      progressDocId: progressDoc.progressDocId,
                      projectId: docExercise.projectId,
                      section: docExercise.section,
                      chapter: docExercise.chapter,
                      exerciseNumber: docExercise.exerciseNumber,
                      isCheckoff: docExercise.isCheckoff
                        ? docExercise.isCheckoff
                        : false,
                      isBonus: docExercise.isBonus
                        ? docExercise.isBonus
                        : false,
                      isHomework: docExercise.isHomework
                        ? docExercise.isHomework
                        : false,
                      description: !docExercise.isHomework
                        ? docExercise.description
                        : "",
                      isLocked:
                        !chapterLockData[
                          `project=${docExercise.projectId}&section=${docExercise.section}&chapter=${docExercise.chapter}`
                        ],
                      isCompleted: docExercise.id in progressDict,
                      assignedTimestamp: new Date(),
                      deadlineTimestamp: new Date(),
                      homeworkCompletion: null,
                    };

                    // console.log(progressDict);
                    // console.log(exercise);
                    if (
                      lastProjectId != exercise.projectId ||
                      lastSection != exercise.section ||
                      lastChapter != exercise.chapter
                    ) {
                      projects[lastProjectId][lastSection - 1][
                        lastChapter - 1
                      ] = chapterExercises;
                      chapterExercises = [];
                    }
                    if (docExercise.hasSuccess) {
                      chapterExercises.push(exercise);
                    }

                    lastProjectId = exercise.projectId;
                    lastSection = exercise.section;
                    lastChapter = exercise.chapter;
                  }
                  projects[lastProjectId][lastSection - 1][lastChapter - 1] =
                    chapterExercises;
                  // console.log(projects);
                  setChapterNames(chapterNames);
                  setProjects(projects);
                } else {
                  // SHOULD SETUP FIREBASE CLOUD FUNCTIONS TO MAKE SURE THIS GETS CREATED AND REMOVE FROM TUTORIALS AS WELL
                  // db.collection("progress")
                  //   .doc()
                  //   .set({
                  //     userId: params.userId,
                  //     projectId: "essentials",
                  //     data: {},
                  //   })
                  //   .then(() => {
                  //     console.log("Document successfully written!");
                  //   })
                  //   .catch((error) => {
                  //     console.error("Error writing document: ", error);
                  //   });
                }
              });
          });
      });
    });
  }, [userData, students]);

  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <Grid
        container
        spacing={2}
        pb={"100px"}
        alignItems="center"
        direction="column"
      >
        <Grid
          item
          style={{ width: 700, marginTop: 20, paddingLeft: 0, marginLeft: 0 }}
        >
          {userData.isTeacher ? (
            <IconButton
              component={Link}
              to="/dashboard"
              style={{ marginBottom: 5 }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : null}
          <Typography display="inline" variant="h6" style={{ marginLeft: 5 }}>
            {student ? student.name : ""}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {projects ? (
            ["essentials", "ping-pong-launcher", "smart-doorbell"].map(
              (projectId, keyIndex) =>
                projectId in projects &&
                projects[projectId].map(
                  (sectionExercises: Exercise[][], sectionIndex: number) => (
                    <Box mt={6}>
                      <Box mb={2}>
                        <Typography variant="h5">
                          {
                            // exercises[0].isHomework
                            //   ? "Homework #" + exercises[0].chapter
                            convertToProjectName(projectId) +
                              " Kit: Section " +
                              (sectionIndex + 1)
                          }
                        </Typography>
                      </Box>
                      <ChaptersTable
                        exercises={sectionExercises}
                        names={
                          chapterNames[
                            "project=" +
                              projectId +
                              "&section=" +
                              (sectionIndex + 1)
                          ]
                        }
                      />
                    </Box>
                  )
                )
            )
          ) : (
            <>
              {[...Array(2)].map((e, i) => (
                <Box mt={6} style={{ width: "700px" }}>
                  <Box mb={2}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={50}
                      width={100}
                      style={{ textAlign: "left" }}
                    />
                    {[...Array(3)].map((e, i) => (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        height={80}
                        width="100%"
                        style={{ textAlign: "left" }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </>
          )}

          {/* {sectionExercises ? (
            sectionExercises.map((exercises: Exercise[], index) => (
              <Box mt={6}>
                <Box mb={2}>
                  <Typography variant="h5">
                    {
                      // exercises[0].isHomework
                      //   ? "Homework #" + exercises[0].chapter
                      convertToProjectName(exercises[0].projectId) +
                        " Kit: Section " +
                        exercises[0].section
                    }
                  </Typography>
                </Box>
                <ChaptersTable exercises={exercises} />
              </Box>
            ))
          ) : (
            <>
              {[...Array(2)].map((e, i) => (
                <Box mt={6} style={{ width: "700px" }}>
                  <Box mb={2}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={50}
                      width={100}
                      style={{ textAlign: "left" }}
                    />
                    {[...Array(3)].map((e, i) => (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        height={80}
                        width="100%"
                        style={{ textAlign: "left" }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </>
          )} */}
        </Grid>
      </Grid>
    </Box>
  );
}
