import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/general/header/Header";
import { Switch, Route, Redirect } from "react-router";
import ListStudentsPage from "./pages/list-students-page/ListStudentsPage";
import TutorialsPage from "./pages/tutorials-page/TutorialsPage";
import StudentDashboardPage from "./pages/student-dashboard-page/StudentDashboardPage";
import Loading from "./components/general/loading/Loading";
import LoginPage from "./pages/login-page/LoginPage";
import AuthContext from "./store/auth-context";

import { blue } from "@mui/material/colors";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import firebase, { db, auth } from "./utils/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { Student, Exercise, Classroom } from "./types/general-types";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: { main: "#00a000" },
    secondary: {
      main: "#f44336",
    },
  },
});

// FIREBASE LOGIN STUFF
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function orderStudents(students: Student[]) {
  // students.sort((a, b) => a.classroomPeriod - b.classroomPeriod);
  students.sort(function (a, b) {
    var textA = (
      a.name.split(" ").length > 1 ? a.name.split(" ")[1].toUpperCase() : ""
    ).toUpperCase();
    var textB = (
      b.name.split(" ").length > 1 ? b.name.split(" ")[1].toUpperCase() : ""
    ).toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  // students.sort((a, b) => a.name.localeCompare(b.name));

  return students;
}

function App() {
  // Listen to the Firebase Auth state and set the local state.
  const [isLoading, setIsLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [hasRegisteredCode, setHasRegisteredCode] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);
  const [classroomProjectsData, setClassroomProjectsData] = useState<
    any | null
  >({});
  const [chapterNames, setChapterNames] = useState<any | null>([]);
  console.log("classroomprojectsdata App.tsx");
  console.log(classroomProjectsData);
  useEffect(() => {
    // .onSnapshot((querySnapshot) => {
    async function fetchMyAPI() {
      // const querySnapshot = await db.collection("classrooms").get();
      // let classrooms: any = {};
      // querySnapshot.forEach((doc) => {
      //   const classroom: Classroom = {
      //     id: doc.id,
      //     name: doc.data().name,
      //     period: doc.data().period,
      //     schoolId: doc.data().schoolId,
      //     teacherId: doc.data().teacherId,
      //   };
      //   classrooms[doc.id] = classroom;
      // });
      const unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(async (user) => {
          if (user) {
            db.collection("users")
              .doc(user.uid)
              .onSnapshot(async (doc) => {
                // if (doc.data()) {

                // }

                const classDoc = doc.data();

                const classroomId =
                  classDoc != undefined && "classroomId" in classDoc
                    ? classDoc.classroomId
                    : undefined;

                const classroomDataDoc = await db
                  .collection("classrooms")
                  .doc(classroomId)
                  .get();
                console.log("classroom data:");
                console.log(classroomDataDoc.data());
                const classroomData = classroomDataDoc.data();
                const classroomProjects =
                  classroomData != undefined && "projects" in classroomData
                    ? classroomData.projects
                    : [];
                setIsSignedIn(!!user);
                setUserData({
                  id: user.uid,
                  classroomProjects: classroomProjects,
                  ...doc.data(),
                });
                setIsLoading(false);
                const docData: any = doc.data();

                if (
                  "isTeacher" in docData &&
                  docData.isTeacher &&
                  docData.myClassrooms
                ) {
                  // const myClassrooms = docData.myClassrooms
                  //   ? docData.myClassrooms
                  //   : [];
                  // if (myClassrooms)
                  for (const classroomId of docData.myClassrooms) {
                    var classroomContentRef = db
                      .collection("classroomContent")
                      // .where("classroomIds", "array-contains", student.classroomId);
                      // change back to the way above later
                      .where(
                        "classroomIds",
                        "array-contains",
                        "derxNawjKfsyyx60ymu8"
                      );
                    classroomContentRef.onSnapshot((querySnapshot) => {
                      console.log("classroom content");
                      querySnapshot.forEach((doc) => {
                        const docExercises = doc.data().exercises;
                        const docChapterDueDateData =
                          doc.data().chapterDueDateData;

                        // get chapterlockdata from chapterLockData collection
                        console.log("CLASSROOM ID");
                        console.log(classroomId);
                        db.collection("chapterLockData")
                          .doc(classroomId)
                          .onSnapshot((chapterLockDataDoc: any) => {
                            // do something with the chapter lock data
                            let chapterLockData =
                              chapterLockDataDoc.data() &&
                              "data" in chapterLockDataDoc.data()
                                ? chapterLockDataDoc.data().data
                                : {};

                            let projects: any = {};
                            let projectExercises: Exercise[][][] = [];
                            let sectionExercises: Exercise[][] = [];
                            let chapterExercises: Exercise[] = [];

                            for (const projectId in doc.data()
                              .sectionsChaptersCount) {
                              if (
                                classroomProjects.length > 0 &&
                                !classroomProjects.includes(projectId)
                              ) {
                                continue;
                              } else if (
                                classroomProjects.length == 0 &&
                                projectId == "smart-doorbell"
                              ) {
                                continue;
                              }
                              projectExercises = Array(
                                doc.data().sectionsChaptersCount[projectId]
                                  .length
                              );
                              let i = 0;
                              for (const section of doc.data()
                                .sectionsChaptersCount[projectId]) {
                                sectionExercises = [...Array(section)].map(
                                  (e) => Array(0)
                                );
                                projectExercises[i] = sectionExercises;
                                i += 1;
                              }

                              projects[projectId] = projectExercises;
                            }
                            console.log("projects:");
                            console.log(docExercises[0].projectId);
                            console.log(projects);

                            let lastProjectId =
                              classroomProjects.length > 0
                                ? "smart-doorbell"
                                : "essentials";
                            let lastSection = 1;
                            let lastChapter = 1;
                            for (const docExercise of docExercises) {
                              let projectId = docExercise.projectId;

                              if (
                                classroomProjects.length > 0 &&
                                !classroomProjects.includes(projectId)
                              ) {
                                continue;
                              } else if (
                                classroomProjects.length == 0 &&
                                projectId == "smart-doorbell"
                              ) {
                                continue;
                              }

                              docExercise["isLocked"] =
                                !chapterLockData[
                                  `project=${docExercise.projectId}&section=${docExercise.section}&chapter=${docExercise.chapter}`
                                ];

                              console.log(docExercise);
                              if (
                                lastProjectId != docExercise.projectId ||
                                lastSection != docExercise.section ||
                                lastChapter != docExercise.chapter
                              ) {
                                console.log("this is projects 2");
                                console.log(classroomProjects.length);
                                console.log(projects);
                                console.log(lastProjectId);
                                console.log(projects[lastProjectId]);
                                projects[lastProjectId][lastSection - 1][
                                  lastChapter - 1
                                ] = chapterExercises;
                                chapterExercises = [];
                              }

                              if (docExercise.isCheckoff) {
                                chapterExercises.push(docExercise);
                              }

                              lastProjectId = docExercise.projectId;
                              lastSection = docExercise.section;
                              lastChapter = docExercise.chapter;
                            }
                            projects[lastProjectId][lastSection - 1][
                              lastChapter - 1
                            ] = chapterExercises;
                            console.log("MY PROJECTS");
                            console.log(projects);
                            setChapterNames(chapterNames);
                            setClassroomProjectsData((prevState: any) => {
                              return {
                                ...prevState,
                                [classroomId]: projects,
                              };
                            });
                          });
                      });
                    });
                  }

                  db.collection("users")
                    .where("classroomId", "in", docData.myClassrooms)
                    .onSnapshot((querySnapshot) => {
                      // .onSnapshot((querySnapshot) => {
                      let students: Student[] = [];
                      querySnapshot.forEach((doc) => {
                        const student: Student = {
                          id: doc.id,
                          name: doc.data().name,
                          email: doc.data().email,
                          classroomPeriod: doc.data().period,
                          classroomId: doc.data().classroomId,
                        };
                        students.push(student);
                      });
                      orderStudents(students);

                      setStudents(students);
                    });
                }
              });
          } else {
            // window.location.href = "/tutorials";
            setIsSignedIn(false);
            setUserData(null);
            setHasRegisteredCode(false);
            setIsLoading(false);
          }
        });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }
    fetchMyAPI();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={userData}>
        <Header
          isSignedIn={isSignedIn}
          hasRegisteredCode={userData && userData.classroomId ? true : false}
        />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {isSignedIn && userData && userData.classroomId ? (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                {userData.isTeacher ? (
                  <Route exact path="/dashboard">
                    <ListStudentsPage
                      students={students}
                      classroomProjectsData={classroomProjectsData}
                    />
                  </Route>
                ) : (
                  <Route exact path="/dashboard">
                    <Redirect
                      to={"/dashboard/student/" + auth.currentUser?.uid}
                    />
                  </Route>
                )}

                <Route exact path="/dashboard/student/:userId">
                  <StudentDashboardPage
                    userData={userData}
                    students={students}
                    classroomProjectsData={classroomProjectsData}
                  />
                </Route>
              </Switch>
            ) : (
              <LoginPage isSignedIn={isSignedIn} />
            )}
          </>
        )}
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
