import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  Paper,
  Grid,
  Box,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import ChapterRow from "./components/chapter-row/ChapterRow";
import styles from "./ChaptersTable.module.css";
import { Exercise } from "../../../types/general-types";

export default function ChaptersTable({ exercises, names }: any) {
  console.log("exercises:");
  console.log(exercises);
  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table
          aria-label="collapsible table"
          className={styles.table}
          style={{ width: 700 }}
        >
          {/* <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Chapter</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {exercises &&
              exercises.map(
                (chapterExercises: Exercise[], chapterIndex: number) => (
                  <ChapterRow
                    number={chapterIndex + 1}
                    name={names[chapterIndex]}
                    exercises={chapterExercises}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
