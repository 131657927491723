import React from "react";

import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function CheckoffChip() {
  return (
    <Chip
      color="default"
      size="small"
      icon={<CheckIcon />}
      label={"Checkoff"}
    />
  );
}
