import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import ProgressStateColorBox from "../progress-state-color-box/ProgressStateColorBox";
import { Exercise } from "../../../../../types/general-types";
import { db } from "../../../../../utils/firebase";
import CheckCircleButton from "../../../../general/check-circle-button/CheckCircleButton";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function StudentRow({
  id,
  name,
  email,
  classroomPeriod,
  projectsData,
  setClassroomStudentsProjectsCSVData,
  classroomStudentsProjectsCSVData,
}: any) {
  const [studentProjectExercises, setStudentProjectExercises] = useState<
    any | null
  >();

  let totalExercisesCount = 0;
  let completedExercisesCount = 0;
  if (studentProjectExercises) {
    for (const sectionExercises of studentProjectExercises) {
      for (const chapterExercises of sectionExercises) {
        for (const exercise of chapterExercises) {
          if (!exercise.isBonus) {
            if (exercise.isCompleted) {
              completedExercisesCount += 1;
            }
            totalExercisesCount += 1;
          }
        }
      }
    }
  }

  console.log("PROJECTS DATA");
  console.log(projectsData);
  console.log(name);
  console.log(id);
  console.log(totalExercisesCount);
  console.log(studentProjectExercises);

  useEffect(() => {
    console.log("trigger the useeffect");
    if (projectsData.length <= 0) {
      return;
    }
    db.collection("progress")
      .where("userId", "==", id)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.size > 0) {
          console.log("PROGRESS CHANGED - " + name);
          let projectsProgress: any = {};
          querySnapshot.forEach((doc) => {
            projectsProgress[doc.data().projectId] = {
              progressDocId: doc.id,
              projectId: doc.data().projectId,
              progressDict: doc.data().data,
            };
          });
          console.log("projects progress:");
          console.log(projectsProgress);
          const progressDocEssentials = projectsProgress
            ? projectsProgress["essentials"]
            : {};
          const progressDictEssentials =
            "progressDict" in progressDocEssentials
              ? progressDocEssentials.progressDict
              : undefined;

          const progressDocPPL = projectsProgress
            ? projectsProgress["ping-pong-launcher"]
            : {};
          const progressDictPPL =
            "progressDict" in progressDocPPL
              ? progressDocPPL.progressDict
              : undefined;

          const progressDocSmartDoorbell = projectsProgress
            ? projectsProgress["smart-doorbell"]
            : {};
          const progressDictSmartDoorbell = progressDocSmartDoorbell
            ? progressDocSmartDoorbell.progressDict
            : null;

          const progressDicts =
            "essentials" in projectsData
              ? [progressDictEssentials, progressDictPPL]
              : [progressDictSmartDoorbell];
          const progressDocs =
            "essentials" in projectsData
              ? [progressDocEssentials, progressDocPPL]
              : [progressDocSmartDoorbell];
          const essentialsKitData =
            "essentials" in projectsData ? projectsData["essentials"] : [];
          const pingPongLauncherData =
            "ping-pong-launcher" in projectsData
              ? projectsData["ping-pong-launcher"]
              : [];
          const smartDoorbellData =
            "smart-doorbell" in projectsData
              ? projectsData["smart-doorbell"]
              : [];

          // const kitsData = [essentialsKitData, pingPongLauncherData];

          const kitsData =
            "essentials" in projectsData
              ? [essentialsKitData, pingPongLauncherData]
              : [smartDoorbellData];
          console.log("essentials kit data");
          console.log(name);
          console.log(id);
          console.log(essentialsKitData);
          let studentProjectExercises = [];
          for (var i = 0; i < kitsData.length; i++) {
            const kitData = kitsData[i];
            const progressDict = progressDicts[i];
            console.log("progress dict in the for loop");
            console.log(kitsData);
            console.log(i);
            console.log(progressDicts);
            console.log(progressDict);

            const progressDoc = progressDocs[i];
            for (const sectionExercises of kitData) {
              let studentSectionExercises = [];
              for (const chapterExercises of sectionExercises) {
                let studentChapterExercises = [];
                for (const chapterExercise of chapterExercises) {
                  const exercise: Exercise = {
                    id: chapterExercise.id,
                    progressDocId: progressDoc.progressDocId,
                    projectId: chapterExercise.projectId,
                    section: chapterExercise.section,
                    chapter: chapterExercise.chapter,
                    exerciseNumber: chapterExercise.exerciseNumber,
                    isCheckoff: chapterExercise.isCheckoff
                      ? chapterExercise.isCheckoff
                      : false,
                    isBonus: chapterExercise.isBonus
                      ? chapterExercise.isBonus
                      : false,
                    isHomework: chapterExercise.isHomework
                      ? chapterExercise.isHomework
                      : false,
                    description: !chapterExercise.isHomework
                      ? chapterExercise.description
                      : "",
                    isLocked: chapterExercise.isLocked,
                    isCompleted: chapterExercise.id in progressDict,
                    assignedTimestamp: new Date(),
                    deadlineTimestamp: new Date(),
                    homeworkCompletion: null,
                  };
                  studentChapterExercises.push(exercise);
                }

                studentSectionExercises.push(studentChapterExercises);
              }

              studentProjectExercises.push(studentSectionExercises);
            }
          }
          setStudentProjectExercises(studentProjectExercises);
          let newClassroomStudentsData = classroomStudentsProjectsCSVData;
          newClassroomStudentsData[id] = {
            id: id,
            name: name,
            email: email,
            classroomPeriod: classroomPeriod,
            studentProjectExercises: studentProjectExercises,
          };

          setClassroomStudentsProjectsCSVData(newClassroomStudentsData);
        }
      });
  }, [projectsData, id]);
  console.log("row");
  return (
    <TableRow key={"name"}>
      <TableCell
        component="th"
        scope="row"
        sx={{
          position: "sticky",
          left: 0,
          background: "white",
          zIndex: 2,
        }}
      >
        <Link to={"/dashboard/student/" + id}>
          {(name.split(" ").length > 1
            ? name.split(" ")[1].toUpperCase()
            : "") +
            ", " +
            name.split(" ")[0]}
        </Link>
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="center">{classroomPeriod}</TableCell>
      <TableCell></TableCell>

      {/* <TableCell>3/28</TableCell>
      <TableCell align="center">
        <ProgressStateColorBox color="#4BB543" />
      </TableCell>
      <TableCell align="center">
        <ProgressStateColorBox color="#F4E400" />
      </TableCell>
      <TableCell align="center">
        <ProgressStateColorBox color="#FF0000" />
      </TableCell>
      <TableCell align="center">
        <ProgressStateColorBox color="#FF0000" />
      </TableCell>
      <TableCell align="center">
        <ProgressStateColorBox color="#FF0000" />
      </TableCell> */}
      <TableCell>
        {completedExercisesCount + "/" + totalExercisesCount}
      </TableCell>
      {studentProjectExercises &&
        studentProjectExercises.map(
          (sectionExercises: any, sectionIndex: Number) => (
            <>
              {sectionExercises.map(
                (chapterExercises: any, chapterId: Number) => (
                  <>
                    {chapterExercises.map(
                      (exercise: Exercise, chapterIndex: Number) => (
                        <TableCell align="center">
                          <CheckCircleButton
                            exerciseId={exercise.id}
                            progressDocId={exercise.progressDocId}
                            isCheckoff={exercise.isCheckoff}
                            isCompleted={exercise.isCompleted}
                            isLocked={exercise.isLocked}
                            size="small"
                          />
                        </TableCell>
                      )
                    )}
                  </>
                )
              )}
            </>
          )
        )}

      {/* <TableCell></TableCell>
      <TableCell>0/37</TableCell>
      {[...Array(5)].map((x, i) => (
        <TableCell align="center">
          <ProgressStateColorBox color="#FF0000" />
        </TableCell>
      ))} */}
    </TableRow>
  );
}
