import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  IconButton,
  Button,
} from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";
import SearchIcon from "@mui/icons-material/Search";
import { Download } from "@mui/icons-material";
import SearchBar from "./components/search-bar/SearchBar";
import StudentsTable from "./components/students-table/StudentsTable";
import StudentRow from "./components/student-row/StudentRow";

import { Exercise } from "../../../types/general-types";

import {
  Student,
  StudentsTableSearchProps,
} from "../../../types/general-types";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function StudentsTableSearch({
  students,
  classroomProjectsData,
}: StudentsTableSearchProps) {
  const [filteredStudents, setFilteredStudents] = useState<Student[]>(students);
  const [
    classroomStudentsProjectsCSVData,
    setClassroomStudentsProjectsCSVData,
  ] = useState({});
  const [transactionData, setTransactionData] = useState<any[][]>([[]]);
  const [headersData, setHeadersData] = useState<string[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [period, setPeriod] = React.useState("1");
  const classes = useStyles();

  const csvLink: any = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const getTransactionData = async () => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    // await api
    //   .post("/api/get_transactions_table", { game_id: gameId })
    //   .then((r) => setTransactionData(r.data))
    //   .catch((e) => console.log(e));
    console.log("classroom projects data:");
    console.log(classroomStudentsProjectsCSVData);

    // set the header
    const headersDataLocal = ["name", "email", "period"];

    classroomProjectsData &&
      Object.keys(classroomProjectsData).length > 0 &&
      Object.keys(classroomProjectsData[Object.keys(classroomProjectsData)[0]])
        .length &&
      "essentials" in
        classroomProjectsData[Object.keys(classroomProjectsData)[0]] &&
      classroomProjectsData[Object.keys(classroomProjectsData)[0]][
        "essentials"
      ].map((sectionExercises: any, sectionIndex: Number) => (
        <>
          {sectionExercises.map((chapterExercises: any, chapterId: Number) => (
            <>
              {chapterExercises.map(
                (exercise: Exercise, chapterIndex: Number) => {
                  const exerciseName =
                    (exercise.projectId == "essentials" ? "E" : "PPL") +
                    " " +
                    exercise.section +
                    "." +
                    exercise.chapter +
                    "-" +
                    exercise.exerciseNumber +
                    (exercise.isBonus ? "B" : "");
                  headersDataLocal.push(exerciseName);
                }
              )}
            </>
          ))}
        </>
      ));
    classroomProjectsData &&
      Object.keys(classroomProjectsData).length > 0 &&
      Object.keys(classroomProjectsData[Object.keys(classroomProjectsData)[0]])
        .length &&
      "ping-pong-launcher" in
        classroomProjectsData[Object.keys(classroomProjectsData)[0]] &&
      classroomProjectsData[Object.keys(classroomProjectsData)[0]][
        "ping-pong-launcher"
      ].map((sectionExercises: any, sectionIndex: Number) => (
        <>
          {sectionExercises.map((chapterExercises: any, chapterId: Number) => (
            <>
              {chapterExercises.map(
                (exercise: Exercise, chapterIndex: Number) => {
                  const exerciseName =
                    (exercise.projectId == "essentials" ? "E" : "PPL") +
                    " " +
                    exercise.section +
                    "." +
                    exercise.chapter +
                    "-" +
                    exercise.exerciseNumber +
                    (exercise.isBonus ? "B" : "");
                  headersDataLocal.push(exerciseName);
                }
              )}
            </>
          ))}
        </>
      ));

    classroomProjectsData &&
      Object.keys(classroomProjectsData).length > 0 &&
      Object.keys(classroomProjectsData[Object.keys(classroomProjectsData)[0]])
        .length &&
      "smart-doorbell" in
        classroomProjectsData[Object.keys(classroomProjectsData)[0]] &&
      classroomProjectsData[Object.keys(classroomProjectsData)[0]][
        "smart-doorbell"
      ].map((sectionExercises: any, sectionIndex: Number) => (
        <>
          {sectionExercises.map((chapterExercises: any, chapterId: Number) => (
            <>
              {chapterExercises.map(
                (exercise: Exercise, chapterIndex: Number) => {
                  const exerciseName =
                    "SDB" +
                    " " +
                    exercise.section +
                    "." +
                    exercise.chapter +
                    "-" +
                    exercise.exerciseNumber +
                    (exercise.isBonus ? "B" : "");
                  headersDataLocal.push(exerciseName);
                }
              )}
            </>
          ))}
        </>
      ));
    await setHeadersData(headersDataLocal);

    let finalData = [];
    for (const [key, value] of Object.entries(
      classroomStudentsProjectsCSVData
    )) {
      console.log(key, value);
      let studentDataElem = [];
      let valueDict: any = value;
      studentDataElem.push(valueDict["name"]);
      studentDataElem.push(valueDict["email"]);
      studentDataElem.push(valueDict["classroomPeriod"]);

      const studentProjectExercises = valueDict["studentProjectExercises"];
      for (const sectionExercises of studentProjectExercises) {
        for (const chapterExercises of sectionExercises) {
          for (const chapterExercise of chapterExercises) {
            studentDataElem.push(chapterExercise.isCompleted);
          }
        }
      }
      finalData.push(studentDataElem);
    }
    await setTransactionData(finalData);
    csvLink.current.link.click();
  };

  const filterByPeriod = (students: Student[]) => {
    const filteredStudents = students.filter((row) => {
      return row.classroomPeriod && row.classroomPeriod.toString() == period;
    });
    return filteredStudents;
  };
  const filterByName = (students: Student[]) => {
    students.sort(function (a, b) {
      var textA = (
        a.name.split(" ").length > 1 ? a.name.split(" ")[1].toUpperCase() : ""
      ).toUpperCase();
      var textB = (
        b.name.split(" ").length > 1 ? b.name.split(" ")[1].toUpperCase() : ""
      ).toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return students;
  };

  const requestSearch = (searchedValEvent: any) => {
    const searchedVal = searchedValEvent.target.value;
    let filteredStudents = students.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    // filteredStudents = filterByPeriod(filteredStudents);
    // filteredStudents = filterByName(filteredStudents);
    setFilteredStudents(filteredStudents);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  console.log("CLASSROOM PROJECTS DATA studentstablesearch");
  console.log(classroomProjectsData);

  useEffect(() => {
    let filteredStudents = filterByPeriod(students);
    // let filteredStudents = filterByName(students);
    setFilteredStudents(filteredStudents);
  }, [students, period]);

  return (
    <>
      <Paper>
        {/* <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        /> */}

        <SearchBar onChange={requestSearch} />
        <div style={{ display: "inline-flex" }}>
          <Button
            onClick={getTransactionData}
            variant="contained"
            startIcon={<Download />}
            sx={{ margin: 2 }}
          >
            Download CSV
          </Button>
          <CSVLink
            filename={
              "class-data-" + moment().format("MM_DD_YYYY-hh_mm_ss") + ".csv"
            }
            headers={headersData}
            data={transactionData}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </div>
        <StudentsTable
          classroomProjectsData={classroomProjectsData}
          period={period}
          setPeriod={setPeriod}
        >
          {filteredStudents.map((student) => (
            <StudentRow
              key={student.id}
              id={student.id}
              name={student.name}
              email={student.email}
              classroomPeriod={student.classroomPeriod}
              projectsData={
                student.classroomId in classroomProjectsData
                  ? classroomProjectsData[student.classroomId]
                  : []
              }
              classroomStudentsProjectsCSVData={
                classroomStudentsProjectsCSVData
              }
              setClassroomStudentsProjectsCSVData={
                setClassroomStudentsProjectsCSVData
              }
              // progress={student.progress}
              // lastUpdated={student.lastUpdated}
            />
          ))}
        </StudentsTable>
      </Paper>
    </>
  );
}
