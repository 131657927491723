import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  Paper,
  Grid,
  Box,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import ExerciseRow from "./components/exercise-row/ExerciseRow";
import styles from "./ExercisesTable.module.css";
import { Exercise } from "../../../types/general-types";

export default function ExercisesTable({ exercises }: any) {
  console.log("exercises:");
  console.log(exercises);
  return (
    <Box sx={{ margin: 1, marginLeft: 7, marginRight: 1 }}>
      <TableContainer>
        <Table
          aria-label="collapsible table"
          className={styles.table}
          style={{ width: 700 }}
          size="small"
        >
          {/* <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Total price ($)</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {exercises &&
              exercises.map((exercise: Exercise) => (
                <ExerciseRow
                  id={exercise.id}
                  progressDocId={exercise.progressDocId}
                  name={
                    exercise.isBonus
                      ? "Bonus #" + exercise.exerciseNumber
                      : "Exercise #" + exercise.exerciseNumber
                  }
                  project={exercise.projectId}
                  description={exercise.description}
                  section={exercise.section}
                  chapter={exercise.chapter}
                  isHomework={exercise.isHomework}
                  isCheckoff={exercise.isCheckoff}
                  isBonus={exercise.isBonus}
                  assignedTimestamp={exercise.assignedTimestamp}
                  deadlineTimestamp={exercise.deadlineTimestamp}
                  isCompleted={exercise.isCompleted}
                  isLocked={exercise.isLocked}
                  homeworkCompletion={exercise.homeworkCompletion}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
