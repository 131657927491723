import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink } from "react-router-dom";

import Logo from "../../../assets/general/logo.png";

import styles from "./Header.module.css";
import Grid from "@mui/material/Grid";

import { auth } from "../../../utils/firebase";

// const StyledLoginButton = styled(Button)({
//   float: "right",
// });

const style = {
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .30)",
};

function logoutFirebase() {
  auth
    .signOut()
    .then(() => {
      console.log("signed out");
    })
    .catch((error) => {
      console.log("error signing out");
    });
}

export default function Header({ isSignedIn, hasRegisteredCode }: any) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Link to="/">
            <img src={Logo} alt="logo" className={styles.logo} />
          </Link>

          {isSignedIn ? (
            <Grid container justifyContent="flex-end">
              {hasRegisteredCode ? (
                <>
                  <Button
                    activeClassName={styles.activeButton}
                    color="inherit"
                    component={NavLink}
                    to="/dashboard"
                  >
                    Dashboard
                  </Button>
                  <Button color="inherit" href="/tutorials">
                    Tutorials
                  </Button>
                </>
              ) : null}

              <Button color="inherit" onClick={logoutFirebase}>
                Logout
              </Button>

              {/* <Button color="inherit">Login</Button> */}
            </Grid>
          ) : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
