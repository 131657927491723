import React, { useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableHead,
  IconButton,
  Collapse,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import styles from "./ChapterRow.module.css";

import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";

import BonusChip from "../../../../general/bonus-chip/BonusChip";
import CheckoffChip from "../../../../general/checkoff-chip/CheckoffChip";
import HomeworkChip from "../../../../general/homework-chip/HomeworkChip";

import firebase, { db, auth } from "../../../../../utils/firebase";
import AuthContext from "../../../../../store/auth-context";
import ExercisesTable from "../../../exercises-table/ExercisesTable";

export default function ChapterRow({ number, name, exercises }: any) {
  const [open, setOpen] = useState(false);

  let completedExercises = exercises.filter((obj: any) => {
    return obj.isCompleted === true;
  });

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        // sx={{ borderBottom: "unset" }}
      >
        <TableCell width={40}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <Typography
            variant="h6"
            style={{ display: "inline-block", marginRight: "8px" }}
          >
            {"Chapter " + number + " - "}
          </Typography>
          <Typography variant="subtitle1" style={{ display: "inline-block" }}>
            {name}
          </Typography>
        </TableCell>
        {/* <TableCell align="right" component="th" scope="row">
          <Button
            onClick={() =>
              window.open(
                "/tutorials/?project=" +
                  "essentials" +
                  "&section=" +
                  "2" +
                  "&chapter=" +
                  "2",
                "_blank"
              )
            }
            variant="contained"
            color="info"
            size="small"
          >
            View chapter
          </Button>
        </TableCell> */}
        <TableCell align="left" width="80px">
          <Box sx={{ m: 1, position: "relative" }}>
            {/* <IconButton aria-label="expand row" size="large" disabled={true}>
              <CheckCircleOutlineIcon fontSize="large" />
            </IconButton> */}
            <Typography variant="h6">
              {exercises.length > 0
                ? completedExercises.length + "/" + exercises.length
                : "-"}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {exercises.length > 0 ? (
              <ExercisesTable exercises={exercises} />
            ) : (
              // <Box sx={{ m: 10, position: "relative" }}>
              //   <div
              //     style={{
              //       display: "flex",
              //       alignItems: "center",
              //       flexWrap: "wrap",
              //       margin: "0 auto",
              //       textAlign: "center",
              //     }}
              //   >
              //     <SentimentSatisfiedAltIcon fontSize={"large"} />
              //     <Typography variant="h4" align="center" color="gray">
              //       No exercises
              //     </Typography>
              //   </div>
              // </Box>
              <Box sx={{ m: 5, position: "relative", textAlign: "center" }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyItems={"center"}
                >
                  <Grid item style={{ width: "100%" }}>
                    <SentimentSatisfiedAltIcon
                      fontSize={"large"}
                      // color="disabled"
                      sx={{
                        color: "gray",
                        fontSize: "60px",
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="h4" align="center" color="gray">
                      No exercises
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      {/* <TableContainer component={Paper}>
        <Table
          aria-label="collapsible table"
          className={styles.table}
          style={{ width: 700 }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Exercise</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Due date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer> */}
    </React.Fragment>
  );
}
