import React from "react";

import firebase, { auth, db } from "../../utils/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Grid } from "@mui/material";
import EnterAccessCode from "../../components/general/enter-access-code/EnterAccessCode";

const onLogin = (authResult: any, redirectUrl: any) => {
  var user = authResult.user;
  console.log("user:");
  console.log(user);
  const userId = user.uid;
  const name = user.displayName;
  const userEmail = user.email;
  var credential = authResult.credential;
  // Determine if user is new or existing.
  var isNewUser = authResult.additionalUserInfo.isNewUser;
  var providerId = authResult.additionalUserInfo.providerId;
  var operationType = authResult.operationType;
  if (isNewUser) {
    db.collection("users")
      .doc(userId)
      .set({
        name: name,
        email: userEmail,
      })
      .then(() => {
        // console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }
  return false;
};

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  requireDisplayName: true,
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: onLogin,
  },
};

export default function LoginPage({ isSignedIn }: any) {
  return (
    <Grid container spacing={2} direction="column" alignItems="center">
      <Grid
        item
        xs={6}
        style={{ minWidth: "100%", marginTop: 40, textAlign: "center" }}
      >
        {isSignedIn ? (
          <EnterAccessCode />
        ) : (
          <StyledFirebaseAuth
            // style={{ minWidth: "100%", marginTop: 40 }}
            uiConfig={uiConfig}
            firebaseAuth={auth}
          />
        )}
      </Grid>
    </Grid>
  );
}
