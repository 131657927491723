import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Exercise } from "../../../../../types/general-types";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: "max-content",
  },
});

export default function StudentsTable(props: any) {
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    props.setPeriod(event.target.value as string);
  };
  return (
    <TableContainer sx={{ width: "100vw", height: "calc(100vh - 120px)" }}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          {/* <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell></TableCell>
            <TableCell
              align="center"
              colSpan={
                props.classroomProjectsData &&
                Object.keys(props.classroomProjectsData).length > 0 &&
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ]["essentials"].length
              }
            >
              Essentials Kit
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="center" colSpan={6}>
              Ping Pong Launcher Kit
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell
              align="left"
              sx={{
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 3,
              }}
            >
              Name
            </TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell width={70} align="center">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Period</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.period}
                  label="Period"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>Score</TableCell>
            {
              props.classroomProjectsData &&
                Object.keys(props.classroomProjectsData).length > 0 &&
                Object.keys(
                  props.classroomProjectsData[
                    Object.keys(props.classroomProjectsData)[0]
                  ]
                ).length &&
                "essentials" in
                  props.classroomProjectsData[
                    Object.keys(props.classroomProjectsData)[0]
                  ] &&
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ]["essentials"].map(
                  (sectionExercises: any, sectionIndex: Number) => (
                    <>
                      {sectionExercises.map(
                        (chapterExercises: any, chapterId: Number) => (
                          <>
                            {chapterExercises.map(
                              (exercise: Exercise, chapterIndex: Number) => (
                                <TableCell align="center">
                                  {(exercise.projectId == "essentials"
                                    ? "E"
                                    : "PPL") +
                                    " " +
                                    exercise.section +
                                    "." +
                                    exercise.chapter +
                                    "-" +
                                    exercise.exerciseNumber +
                                    (exercise.isBonus ? "B" : "")}
                                </TableCell>
                              )
                            )}
                          </>
                        )
                      )}
                    </>
                  )
                )
              // &&
              // props.classroomProjectsData[
              //   Object.keys(props.classroomProjectsData)[0]
              // ]["ping-pong-launcher"].map(
              //   (sectionExercises: any, sectionIndex: Number) => (
              //     <>
              //       {sectionExercises.map(
              //         (chapterExercises: any, chapterId: Number) => (
              //           <>
              //             {chapterExercises.map(
              //               (exercise: Exercise, chapterIndex: Number) => (
              //                 <TableCell align="center">
              //                   {(exercise.projectId == "essentials"
              //                     ? "E"
              //                     : "PPL") +
              //                     " " +
              //                     exercise.section +
              //                     "." +
              //                     exercise.chapter +
              //                     "-" +
              //                     exercise.exerciseNumber +
              //                     (exercise.isBonus ? "B" : "")}
              //                 </TableCell>
              //               )
              //             )}
              //           </>
              //         )
              //       )}
              //     </>
              //   )
              // )
            }
            {props.classroomProjectsData &&
              Object.keys(props.classroomProjectsData).length > 0 &&
              Object.keys(
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ]
              ).length &&
              "ping-pong-launcher" in
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ] &&
              props.classroomProjectsData[
                Object.keys(props.classroomProjectsData)[0]
              ]["ping-pong-launcher"].map(
                (sectionExercises: any, sectionIndex: Number) => (
                  <>
                    {sectionExercises.map(
                      (chapterExercises: any, chapterId: Number) => (
                        <>
                          {chapterExercises.map(
                            (exercise: Exercise, chapterIndex: Number) => (
                              <TableCell align="center">
                                {(exercise.projectId == "essentials"
                                  ? "E"
                                  : "PPL") +
                                  " " +
                                  exercise.section +
                                  "." +
                                  exercise.chapter +
                                  "-" +
                                  exercise.exerciseNumber +
                                  (exercise.isBonus ? "B" : "")}
                              </TableCell>
                            )
                          )}
                        </>
                      )
                    )}
                  </>
                )
              )}
            {props.classroomProjectsData &&
              Object.keys(props.classroomProjectsData).length > 0 &&
              Object.keys(
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ]
              ).length &&
              "smart-doorbell" in
                props.classroomProjectsData[
                  Object.keys(props.classroomProjectsData)[0]
                ] &&
              props.classroomProjectsData[
                Object.keys(props.classroomProjectsData)[0]
              ]["smart-doorbell"].map(
                (sectionExercises: any, sectionIndex: Number) => (
                  <>
                    {sectionExercises.map(
                      (chapterExercises: any, chapterId: Number) => (
                        <>
                          {chapterExercises.map(
                            (exercise: Exercise, chapterIndex: Number) => (
                              <TableCell align="center">
                                {"SDB" +
                                  " " +
                                  exercise.section +
                                  "." +
                                  exercise.chapter +
                                  "-" +
                                  exercise.exerciseNumber +
                                  (exercise.isBonus ? "B" : "")}
                              </TableCell>
                            )
                          )}
                        </>
                      )
                    )}
                  </>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
}
