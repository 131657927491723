import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar({ onChange }: any) {
  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "inline-flex",
        alignItems: "center",
        width: "calc(100% - 300px)",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search for a student"
        inputProps={{ "aria-label": "search for a student" }}
        onChange={onChange}
      />
      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
