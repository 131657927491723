import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyAe9gqWs_O9547dacO39rMbSwi6FL4oq58",
  authDomain: "juicemind-school.firebaseapp.com",
  projectId: "juicemind-school",
  storageBucket: "juicemind-school.appspot.com",
  messagingSenderId: "998911186431",
  appId: "1:998911186431:web:237a6bac3f70858b4803ca",
};
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export default firebase;
