import React, { useState, useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";

import firebase, { db, auth } from "../../../utils/firebase";
import AuthContext from "../../../store/auth-context";

export default function CheckCircleButton({
  exerciseId,
  progressDocId,
  isCheckoff,
  isCompleted,
  isLocked,
  size,
}: any) {
  const [isUpdating, setIsUpdating] = useState(false);
  const userData: any = useContext(AuthContext);

  const updateTask = () => {
    setIsUpdating(true);
    var dict: any = {};
    var user = auth.currentUser?.uid;

    // isCompleted is the state before the teacher clicks, so we want the opposite in the database
    dict[exerciseId] = isCompleted
      ? firebase.firestore.FieldValue.delete()
      : firebase.firestore.Timestamp.now();
    db.collection("progress")
      .doc(progressDocId)
      .set(
        {
          data: dict,
        },
        { merge: true }
      )
      .then(() => {
        // console.log("Document successfully written for scroll!");
        // jQuery(".code-registration").hide();
        // initializePage();
        // setIsCompletedState()
        // setIsUploading(false);
        setIsUpdating(false);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setIsUpdating(false);
      });
  };

  return (
    <>
      {!isUpdating ? (
        <>
          {userData && userData.isTeacher ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={updateTask}
              disabled={isLocked || !isCheckoff}
              // disabled
            >
              {isLocked ? (
                <LockIcon fontSize={size} />
              ) : isCheckoff && isCompleted ? (
                <CheckCircleIcon fontSize={size} color="success" />
              ) : isCheckoff && !isCompleted ? (
                <CheckCircleOutlineIcon fontSize={size} />
              ) : (
                <Typography variant="body1" sx={{ marginRight: "5px" }}>
                  {(isCompleted ? "1" : "0") + "/1"}
                </Typography>
              )}
            </IconButton>
          ) : (
            <>
              {isLocked ? (
                <LockIcon fontSize={size} />
              ) : isCheckoff && isCompleted ? (
                <CheckCircleIcon fontSize={size} color="success" />
              ) : isCheckoff && !isCompleted ? (
                <CheckCircleOutlineIcon fontSize={size} />
              ) : (
                <Typography variant="body1" sx={{ marginRight: "5px" }}>
                  {(isCompleted ? "1" : "0") + "/1"}
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <CircularProgress
          size={size == "large" ? 40 : size == "small" ? 25 : 25}
          sx={
            {
              //   color: green[500],
              //   position: "absolute",
              //   top: -27,
              //   left: -10,
            }
          }
        />
      )}
    </>
  );
}
