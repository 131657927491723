import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import StudentsTableSearch from "../../components/list-students-page/students-table-search/StudentsTableSearch";

import { Student } from "../../types/general-types";

import { auth, db } from "../../utils/firebase";
import EditTutorialsSectionsAndChapters from "../../components/list-students-page/students-table-search/components/edit-tutorials-sections-and-chapters/EditTutorialsSectionsAndChapters";

export default function ListStudentsPage({
  students,
  classroomProjectsData,
}: any) {
  console.log("classroomprojectsdata liststudentspage");
  console.log(classroomProjectsData);
  return (
    <Grid
      container
      spacing={0}
      p={"0px"}
      alignItems="center"
      direction="column"
      style={{ overflow: "scroll" }}
    >
      <Grid item xs={12}>
        <StudentsTableSearch
          students={students}
          classroomProjectsData={classroomProjectsData}
        />
      </Grid>
    </Grid>
  );
}
