import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@mui/material";

import { auth, db } from "../../../utils/firebase";

import styles from "./EnterAccessCode.module.css";

export default function LoginPage({ isSignedIn }: any) {
  const [accessCode, setAccessCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");
  const history = useHistory();
  console.log("is loading");
  console.log(isLoading);

  const registerCode = () => {
    setShowError(false);
    setIsLoading(true);
    // db.collection("inventory")
    //   .doc(accessCode.toUpperCase())
    //   .update({ userId: auth.currentUser?.uid })
    //   .then((doc) => {
    //     console.log("successfully wrote to document");
    //     setIsLoading(true);
    //   })
    //   .catch((error) => {
    //     // wrong access code
    //     console.error("Error writing document: ", error);
    //     setIsLoading(false);
    //   });
    db.collection("inventory")
      .doc(accessCode.toUpperCase())
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          const docObj = doc.data()!;
          if (!("userId" in docObj) || !docObj.userId || docObj.userId === "") {
            // this is a valid access code
            let usersUpdateDict: any = {
              isTeacher: "isTeacher" in docObj ? docObj.isTeacher : false,
              classroomId: docObj.classroomId,
              accessCode: doc.id,
              period: docObj.period,
            };
            if ("isTeacher" in docObj && docObj.isTeacher) {
              usersUpdateDict["myClassrooms"] = [docObj.classroomId];
            }
            db.collection("users")
              .doc(auth.currentUser?.uid)
              .update(usersUpdateDict)
              .then((doc) => {
                console.log("successfully wrote to document");
                setIsLoading(true);
              })
              .catch((error) => {
                // wrong access code
                console.error("Error writing document: ", error);
                setErrorLabel("Something went wrong! Try again.");
                setShowError(true);
                setIsLoading(false);
              });
          } else {
            setErrorLabel("Invalid access code.");
            setShowError(true);
            setIsLoading(false);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setErrorLabel("Invalid access code.");
          setShowError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // wrong access code
        console.error("Error writing document: ", error);
        setErrorLabel("Invalid access code.");
        setShowError(true);
        setIsLoading(false);
      });
  };
  const accessCodeFieldChanged = (event: any) => {
    setShowError(false);
    const accessCodeVal = event.target.value;
    setAccessCode(accessCodeVal);
  };
  return (
    <div className={styles.enterAccessCode}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Box mb={3}>
            <Typography variant="h5" component="div">
              Last step!
            </Typography>
          </Box>
          <TextField
            error={showError}
            helperText={showError ? errorLabel : null}
            variant="outlined"
            label="Enter access code"
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 6 }}
            style={{ width: "100%" }}
            onChange={accessCodeFieldChanged}
          />
        </CardContent>
        <CardActions sx={{ textAlign: "center", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={accessCode.length != 6}
            onClick={registerCode}
          >
            {isLoading ? <CircularProgress size={25} /> : "Finish!"}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
